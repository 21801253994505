import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Form, Button, Col} from 'react-bootstrap';

const UploadTimeCalculator = () => {
    const [fileSize, setFileSize] = useState(null);
    const [fileType, setFileType] = useState("GB");
    const [uploadSpeed, setUploadSpeed] = useState(null);
    const [uploadSpeedType, setUploadSpeedType] = useState("Mbps");
    const [uploadTimeMin, setUploadTimeMin] = useState(null);

    const calculateUploadTime = () => {
        if (fileSize !== null && fileSize > 0) {
            if (uploadSpeed !== null && uploadSpeed > 0) {
                let tempFileSize;
                let tempUploadSpeed;

                if (fileType === "KB") {
                    tempFileSize = fileSize / 1024;
                } else if (fileType === "MB") {
                    tempFileSize = fileSize;
                } else if (fileType === "GB") {
                    tempFileSize = fileSize * 1024;
                } else if (fileType === "TB") {
                    tempFileSize = fileSize * 1024 * 1024;
                }
                tempFileSize = tempFileSize * 8;

                if (uploadSpeedType === "Kbps") {
                    tempUploadSpeed = uploadSpeed / 1024;
                } else if (uploadSpeedType === "Mbps") {
                    tempUploadSpeed = uploadSpeed;
                } else if (uploadSpeedType === "Gbps") {
                    tempUploadSpeed = uploadSpeed * 1024;
                }

                uploadMinuteToString(tempFileSize / (tempUploadSpeed / 100 * 95.3));
            } else {
                alert("Please enter upload speed greater than 0!");
            }
        } else {
            alert("Please enter file size greater than 0!");
        }

    }

    const uploadMinuteToString = (seconds) => {
        let numYears = Math.floor(seconds / 31536000);
        let numDays = Math.floor((seconds % 31536000) / 86400);
        let numHours = Math.floor(((seconds % 31536000) % 86400) / 3600);
        let numMinutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
        let numSeconds = Math.floor((((seconds % 31536000) % 86400) % 3600) % 60);

        let result = "";
        if (numYears > 0) {
            result += numYears + " years ";
        }
        if (numDays > 0) {
            result += numDays + " days ";
        }
        if (numHours > 0) {
            result += numHours + " hours ";
        }
        if (numMinutes > 0) {
            result += numMinutes + " minutes ";
        }
        if (numSeconds > 0) {
            result += numSeconds + " seconds ";
        }

        setUploadTimeMin(result);
    }

    return (
        <Form className="utc-form">
            <h1 className="utc-h1">Upload Time Calculator</h1>
            <h2 className="utc-h2">Calculate Upload Time</h2>
            <h3 className="utc-h3">How long will it take to upload?</h3>
            <br/>

            <Form.Group>
                <Form.Label className="utc-form-label">Your File Size</Form.Label>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Control className="utc-form-control" type="number" placeholder="Your File Size" onInput={e => setFileSize(e.currentTarget.value)}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Control as="select" defaultValue="GB" onChange={e => setFileType(e.currentTarget.value)}>
                            <option>KB</option>
                            <option>MB</option>
                            <option>GB</option>
                            <option>TB</option>
                        </Form.Control>
                    </Form.Group>
                </Form.Row>
            </Form.Group>

            <Form.Group>
                <Form.Label className="utc-form-label">Your Upload Speed</Form.Label>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Control className="utc-form-control" type="number" placeholder="Your Upload Speed" onInput={e => setUploadSpeed(e.currentTarget.value)}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Control as="select" defaultValue="Mbps" onChange={e => setUploadSpeedType(e.currentTarget.value)}>
                            <option>Kbps</option>
                            <option>Mbps</option>
                            <option>Gbps</option>
                        </Form.Control>
                    </Form.Group>
                </Form.Row>
                <a href="https://www.speedtest.net/" target="_blank">I dont know my upload speed.</a>
            </Form.Group>

            <div align="right">
                <Button variant="primary" onClick={() => calculateUploadTime()}>
                    Calculate Upload Time
                </Button>
            </div>

            <br/>

            {uploadTimeMin &&
            <Form.Group>
                <br/>
                <h3 className="utc-h3">Your Upload Time</h3>
                <h3 className="utc-h3">{uploadTimeMin}</h3>
                <br/>
            </Form.Group>
            }

            <Form.Group>
                <h3 className="utc-h3">What's Upload Time Calculator?</h3>
                <p className="utc-p">Upload Time Calculator is a online and free tool to calculate how much time it will take to upload file based on upload speed.</p>
            </Form.Group>

            <br/>

            <Form.Group>
                <h3 className="utc-h3">How To Use Upload Time Calculator?</h3>
                <p className="utc-p">To use the tool, you only need to know the size of the file you want to upload and your upload speed. After filling the required fields in the
                    form above with this information, press the calculate upload time button. After that, your load time will appear right under the calculate load time button.</p>
            </Form.Group>

            <br/>

            <Form.Group>
                <h3 className="utc-h3">What's File Size?</h3>
                <p className="utc-p">File size is a measure of how much data a computer file contains or, alternately, how much storage it consumes.</p>
            </Form.Group>

            <br/>

            <Form.Group>
                <h3 className="utc-h3">What's Upload Speed?</h3>
                <p className="utc-p">Upload speed refers to the speed that your internet connection can allow data to be sent from your devices to the internet.</p>
            </Form.Group>

            <br/>

            <Form.Group>
                <h3 className="utc-h3">How To Reduce My Upload Time?</h3>
                <p className="utc-p">Increasing your upload speed or reducing your file size will reduce your upload time.</p>
            </Form.Group>
        </Form>
    );
}

export default UploadTimeCalculator;