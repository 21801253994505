import React, {Component} from 'react';
import './App.css';
import UploadTimeCalculator from './components/UploadTimeCalculator';

export default class App extends Component {
    render() {
        return (
            <div className="upload-time-calculator">
                <UploadTimeCalculator></UploadTimeCalculator>
            </div>
        );
    }
}